if( document.readyState !== 'loading' ) {
  mainCode();
} else {
  document.addEventListener('DOMContentLoaded', function () {
      mainCode();
  });
}

function mainCode() {
  const headerMenu = document.getElementsByClassName("menu-container")[0];

  headerMenu.addEventListener("click", (e) => {

      const targetLink = e.target;
      const modal = document.getElementById("sideManu");
      if (targetLink.attributes.href) {
          document.getElementById("globalMenu").checked = false;
          smoothScroll(targetLink.attributes.href.value, 1000);
      }
  });

  const smoothScroll = function(targetEl, duration) {
      // const headerElHeight = document.querySelector('.menu-container').clientHeight;
      // console.log(headerElHeight);
      let target = document.querySelector(targetEl);
      // let targetPosition = target.getBoundingClientRect().top - headerElHeight;
      let targetPosition = target.getBoundingClientRect().top;

      let startPosition = window.pageYOffset;
      let startTime = null;

      const ease = function(t, b, c, d) {
          t /= d / 2;
          if (t < 1) return c / 2 * t * t + b;
          t--;
          return -c / 2 * (t * (t - 2) - 1) + b;
      };

      const animation = function(currentTime) {
          if (startTime === null) startTime = currentTime;
          const timeElapsed = currentTime - startTime;
          const run = ease(timeElapsed, startPosition, targetPosition, duration);
          window.scrollTo(0, run);
          if (timeElapsed < duration) requestAnimationFrame(animation);
      };
      requestAnimationFrame(animation);
  };
  const scrollElements = document.querySelectorAll(".js-scroll");

  const elementInView = (el, dividend = 1.25) => {
  const elementTop = el.getBoundingClientRect().top;

  return (
    elementTop <=
      (window.innerHeight || document.documentElement.clientHeight) / dividend
    );
  };

  const elementOutofView = (el) => {
  const elementTop = el.getBoundingClientRect().top;

  return (
    elementTop > (window.innerHeight || document.documentElement.clientHeight)
  );
  };

  const displayScrollElement = (element) => {
  element.classList.add("scrolled");
  };

  const hideScrollElement = (element) => {
  element.classList.remove("scrolled");
  };

  const handleScrollAnimation = () => {
  scrollElements.forEach((el) => {
    if (elementInView(el, 1.1)) {            
      displayScrollElement(el);
    } else if (elementOutofView(el)) {
      hideScrollElement(el)
    }
  })
  }

const toggleHeaderClass = () => {

    const scrollElementPos = window.pageYOffset;
    const header=document.getElementById('header');

    if(scrollElementPos >= 800){
      header.classList.add("header--fixed");
    }
    else {
      header.classList.remove("header--fixed");
    }
}

toggleHeaderClass();


  document.addEventListener("scroll", () => {
    handleScrollAnimation();

    toggleHeaderClass();

  });

  (function galleryInit(){    
    const images = document.querySelectorAll('.gallery img');    
    const fullPage = document.querySelector('.gallery-fullpage');   
    
    fullPage.addEventListener("click", (e) => {
      e.target.style.display="none";
    })

    images.forEach(img => {
    img.addEventListener('click', function() {
      fullPage.style.backgroundImage = 'url(' + img.src + ')';
      fullPage.style.display = 'block';
      fullPage.classList.add("display");
      });
    });
  })();

}
